.topContainer {
  position: relative;
  .topImg {
    height: 400px;
    width: 100%;
    object-fit: cover;
    background-image: url(../images/topImg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    .logoContainer {
      background-color: rgba(255, 255, 255, 0.8);
      height: 220px;
      width: 250px;
      border-radius: 4px;
      position: absolute;
      top: 50px;
      left: 20%;
      padding: 20px;
      transform: translateX(-50%);
      img {
        width: 60%;
        object-fit: contain;
        display: block;
        margin: 12px auto;
        margin-top: 6px;
      }
      .topP {
        text-align: center;
        font-weight: bold;
      }
      .iconContainer {
        display: flex;
        width: 150px;
        margin: 0 auto;
        img {
          width: 30px;
        }
      }
    }
  }

  .aboutContent {
    display: flex;
    width: 80%;
    margin: 20px auto;
    align-items: center;
    .img {
      display: block;
      width: 50%;
      position: relative;
      &:before {
        content: '';
        display: block;
        padding-top: 100%;
      }
      img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        object-fit: cover;
        display: block;
        border-radius: 50%;
      }
    }
    .info {
      padding: 25px;
      width: 50%;
      p {
        font-size: 14px;
        margin-top: 10px;
      }
    }
  }
  .aboutContent-column-reverse {
    display: flex;
    width: 80%;
    margin: 20px auto;
    align-items: center;
    .img {
      display: block;
      width: 50%;
      position: relative;
      &:before {
        content: '';
        display: block;
        padding-top: 100%;
      }
      img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        object-fit: cover;
        display: block;
        border-radius: 50%;
      }
    }
    .info {
      padding: 25px;
      width: 50%;
      p {
        font-size: 14px;
        margin-top: 10px;
      }
    }
  }
}
.main3 {
  width: 85%;
  margin: 0 auto;
  background-color: white;
  margin-top: -30px;
  margin-bottom: 30px;
  position: relative;
  z-index: 100;
  border: 2px solid #141414;
  padding-bottom: 20px;
  .LINE {
    border: 3px solid #1dcd00;
    border-radius: 20px;
    width: 95%;
    max-width: 800px;
    margin: 0 auto;
    padding: 10px;
    box-sizing: border-box;
    p {
      display: flex;
      align-items: center;
      align-items: center;
      font-weight: bold;
      justify-content: center;
      margin: 3px 0;
      img {
        width: 200px;
      }
    }
    .LINEbutton {
      display: block;
      width: 150px;
      margin: 10px auto 0 auto;
      button {
        width: 100%;
        display: flex;
        background-color: #00b900;
        border-radius: 5px;
        border: none;
        color: white;
        font-size: 16px;
        height: 40px;
        font-weight: bold;
        justify-content: center;
        align-items: center;
        img {
          height: 30px;
        }
      }
    }
  }
}
.flex {
  display: flex;
}
@media (max-width: 800px) {
  .topImg {
    height: 400px !important;
    background-position: center;
    .logoContainer {
      top: 90px !important ;
      left: 50% !important;
    }
  }
  .aboutContent-column-reverse {
    flex-direction: column-reverse;
    width: 90% !important;
    .info {
      width: 100% !important;
      padding: 0 !important;
      text-align: center;
    }
    .img {
      width: 90% !important;
      margin: 10px auto;
    }
  }
  .aboutContent {
    display: block !important;
    width: 90% !important;
    .info {
      width: 100% !important;
      padding: 0 !important;
      text-align: center;
    }
    .img {
      width: 90% !important;
      margin: 10px auto;
    }
  }
  .main3 {
    width: 90%;
  }
  .flex {
    display: block !important;
  }
}
.blank {
  height: 30px;
}
