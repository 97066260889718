.sponsorsContainer {
  text-align: center;
  margin-bottom: 40px;
  .geometry {
    width: 100%;
    height: 300px;
    background-size: cover;
    background-image: url(../images/diagram.jpg);
    background-repeat: no-repeat;
    overflow: hidden;
    position: relative;
    &:before {
      background: inherit;
      content: '';
      filter: blur(5px);
      position: absolute;
      top: -5px;
      right: -5px;
      bottom: -5px;
      left: -5px;
      z-index: 0;
    }
    .titleContainer {
      position: relative;
      z-index: 20;
      padding: 110px 0;
      p {
        color: white;
      }
      div {
        background-color: white;
      }
    }
  }
  .middleTitle {
    margin: 60px 0 30px 0;
  }
  h4 {
    margin: 20px 0 30px 0;
  }
  .sponsorImages {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 95%;
    margin: 0 auto;
    max-width: 1000px;
    .sponsorFiction {
      display: block;
      // display: none;
      width: 180px;
      height: 10px;
      background-color: blue;
    }
    a {
      display: block;
      // スマホで縦長になるからpx指定にしました
      width: 180px;
      border: 2px solid #1c1c1c;
      padding: 5px;
      margin: 5px auto;
      box-sizing: border-box;
      img {
        width: 90%;
        height: 100px;
        object-fit: contain;
        display: block;
        margin: 0px auto;
        margin-top: 10px;
      }
      p {
        font-size: 12px;
        font-weight: bold;
        text-align: center;
        margin-top: 5px;
        margin-bottom: 10px;
      }
    }
  }
  .featuresContainer {
    display: flex;
    flex-wrap: wrap;
    width: 95%;
    margin: 20px auto;
    max-width: 1000px;
    justify-content: space-around;
    margin-top: 80px;
    div {
      // スマホで縦長になるからpx指定にしました
      width: 32%;
      margin-top: 40px;
      background-color: rgb(247, 247, 247);
      position: relative;
      padding: 35px;
      box-sizing: border-box;
      img {
        position: absolute;
        display: block;
        width: 120px;
        border-radius: 50%;
        top: -55px;
        left: 50%;
        transform: translate(-50%);
        border: 15px solid rgb(247, 247, 247);
        box-sizing: border-box;
        background-color: rgb(247, 247, 247);
      }
      h4 {
        margin-top: 50px;
        font-size: 16px;
      }
      p {
        text-align: left;
        margin-top: 10px;
        font-size: 14px;
        font-weight: lighter;
      }
    }
  }
  .serviceImage {
    border: solid 2px #141414; /* 内側の線になる一本線の枠線をひく*/
    padding: 30px;
    box-sizing: border-box;
    max-width: 600px;
    width: 95%;
    margin: 40px auto;
    margin-top: 50px;
    position: relative;
    img {
      display: block;
      width: 95%;
      margin: 10px auto;
    }
    .serviceTitle {
      background-color: white;
      border-left: 2px solid #141414;
      border-right: 2px solid #141414;
      display: inline-block;
      padding: 0px 15px;
      position: absolute;
      bottom: -12px;
      left: 50%;
      transform: translateX(-50%) skewX(-20deg);
      span {
        display: inline-block;
        transform: skewX(20deg);
        font-weight: bold;
        font-size: 13px;
      }
    }
  }
  .contentsContainer {
    display: flex;
    flex-wrap: wrap;
    width: 95%;
    max-width: 800px;
    margin: 20px auto;
    justify-content: space-around;

    div {
      // スマホで縦長になるからpx指定にしました
      width: 350px;
      background-color: rgb(247, 247, 247);
      box-sizing: border-box;
      padding: 35px;
      margin: 10px;
      p {
        margin-top: 10px;
        font-size: 14px;
      }
      h4 {
        margin-top: 0px;
      }
    }
  }
  .toContact {
    font-size: 12px;
    font-weight: bold;
    margin-top: 30px;
  }
}

@media (max-width: 768px) {
  .sponsorImages {
    a {
      width: 30% !important;
    }
  }
  .featuresContainer {
    flex-direction: column;
    div {
      width: 95% !important;
      margin: 0 auto;
      // 縦にならんだとき上のでっぱったまるいの重ならないように
      margin-top: 70px !important;
    }
  }
}
@media (max-width: 690px) {
  .sponsorImages {
    a {
      width: 45% !important;
    }
  }
}
