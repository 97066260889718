.paper {
  width: 100%;
  height: 300px;
  background-size: cover;
  background-image: url(../images/paper.jpg);
  background-repeat: no-repeat;
  overflow: hidden;
  position: relative;
  &:before {
    background: inherit;
    content: '';
    filter: blur(3px);
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    z-index: 0;
  }
  .titleContainer {
    position: relative;
    z-index: 20;
    padding: 110px 0;
  }
}
.card {
  border: solid 1px rgb(240, 240, 240);
  background-color: white;
  width: 100%;
  height: 290px;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 6px;
  &:hover {
    background-color: rgb(230, 230, 230);
  }

  img {
    display: block;
    margin: 0 auto;
    width: 100%;
    height: calc(9 / 16 * 100%);
    object-fit: contain;
    // background-color: whitesmoke;
    border-radius: 6px;
  }

  .title {
    font-weight: bold;
  }
}
.firstLine {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .publishDate2 {
    font-size: 12px;
    text-align: right;
  }
}
.category {
  background-color: #141414;
  color: white;
  display: inline-block;
  padding: 1px 15px 3px 15px;
  line-height: 18px;
  transform: skew(-15deg);
  margin: 10px 0;
  span {
    display: inline-block;
    font-weight: bold;
    transform: skew(15deg);
    font-size: 12px;
  }
}
.react-tabs {
  .tablist {
    border-top: 2px solid #141414;
    border-bottom: 2px solid #141414;
    position: sticky;
  }
  .react-tabs__tab-list {
    display: flex;
    width: 90%;
    max-width: 350px;
    height: 40px;
    line-height: 40px;
    margin: 0 auto;
    justify-content: space-between;
    li {
      list-style: none;
      cursor: pointer;
    }
    .react-tabs__tab--selected {
      position: relative;
      font-weight: bold;
      &:after {
        content: '';
        background-color: #141414;
        height: 3px;
        width: 120%;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: skew(-15deg) translateX(-50%);
        transition: all 0.3s ease-in;
      }
    }
  }
  .react-tabs__tab-panel {
    margin-top: 10px;
    .datas {
      width: 32%;
      height: 0;
    }
  }
}
.cardWrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  max-width: 800px;
  margin: 0 auto;
  a {
    display: block;
    width: 250px;
    margin: 10px auto;
  }
}

@media (max-width: 900px) {
  // イベントカードがきれいに2段に表示されるようにがんばった
  .cardWrapper {
    a {
      width: unquote('min(95vw,400px)');
    }
  }
  .card {
    height: 310px;
  }
  // .category {
  //   padding: 0 5px 2px 5px;
  //   line-height: 18px;
  //   span {
  //     font-size: 10px;
  //   }
  // }
  // .publishDate {
  //   font-size: 15px;
  // }
}
@media (max-width: 378px) {
  .cardWrapper {
    a {
      width: 95%;
    }
  }
}
