.reservation {
  text-align: center;
  .light {
    width: 100%;
    height: 300px;
    background-size: cover;
    background-image: url(../images/light.jpg);
    background-repeat: no-repeat;
    overflow: hidden;
    position: relative;
    background-position: center;
    &:before {
      background: inherit;
      content: '';
      filter: blur(3px);
      position: absolute;
      top: -5px;
      right: -5px;
      bottom: -5px;
      left: -5px;
      z-index: 0;
    }
    .titleContainer {
      position: relative;
      z-index: 20;
      padding: 110px 0;
      p {
        color: white;
      }
      div {
        background-color: white;
      }
    }
  }
  h4 {
    margin: 20px 0 30px 0;
  }
  .topContainer {
    background-color: pink;
  }
  .announcement {
    font-size: 13px;
  }
  .middleTitle {
    margin: 60px 0 30px 0;
  }
  .calendar {
    width: 600px;
    margin: 30px auto;
    height: 400px;
  }
}
.content {
  width: 95%;
  max-width: 1500px;
  margin: 10px auto;
}

@media (max-width: 768px) {
  .calendar {
    width: 95% !important;
    height: 300px;
  }
}
.font-weight-600 {
  font-weight: 600;
}
