.toTopLogo {
  text-align: center;
  z-index: 1000;
  a {
    display: inline-block;
    margin: 25px 0;
    .ichisanlabo {
      font-size: 48px;
      font-family: 'Alata', sans-serif;
      font-weight: bold;
      text-align: center;
      line-height: 45px;
    }
    .subIchisanlabo {
      font-size: 12px;
      font-family: 'Alata', sans-serif;
      font-weight: bold;
      text-align: center;
    }
  }
}
.navbarBackground {
  background-color: #141414;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1000;
  .navbar {
    display: flex;
    width: 80%;
    margin: 0 auto;
    height: 42px;
    justify-content: space-between;
    ul {
      display: flex;
      align-items: center;
      justify-content: space-around;
      li {
        color: white;
        list-style: none;
        font-family: 'Alata', sans-serif;
        font-weight: bold;
      }
      .blogs {
        display: flex;
        align-items: center;
        .blogIcon {
          padding-left: 3px;
        }
      }
    }
    .leftNavbar {
      width: 85%;
    }
    .rightNavbar {
      width: 10%;
      position: relative;
      &:before {
        content: '';
        display: inline-block;
        width: 2px;
        height: 25px;
        background-color: white;
        position: absolute;
        left: -25%;
        border-radius: 50px;
        transform: skew(-20deg);
      }
    }
  }
}
.phoneNavbar {
  display: none;
}

@media (max-width: 800px) {
  .toTopLogo {
    display: none;
  }
  .navbarBackground {
    display: none;
  }
  .phoneNavbar {
    display: block;
  }
  .phoneNavbar {
    padding-right: 20px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    background-color: rgba(255, 255, 255, 0.5);
    .toTopLogo2 {
      text-align: left;
      a {
        display: inline-block;
        margin: 10px 0;
        padding-left: 20px;
        .ichisanlabo {
          font-size: 30px;

          line-height: 30px;
        }
        .subIchisanlabo {
          font-size: 12px;
        }
      }
    }
    .btn-trigger {
      z-index: 1000;
      position: relative;
      width: 38px;
      height: 32px;
      cursor: pointer;
      margin-right: 20px;
      span {
        position: absolute;
        left: 0;
        width: 100%;
        height: 4px;
        background-color: #1c1c1c;
        display: inline-block;
        transition: all 0.5s;
        box-sizing: border-box;
        &:nth-of-type(1) {
          top: 0;
        }
        &:nth-of-type(2) {
          top: 14px;
        }
        &:nth-of-type(3) {
          bottom: 0;
        }
      }
    }
    .open {
      span {
        &:nth-of-type(1) {
          -webkit-transform: translateY(14px) rotate(-45deg);
          transform: translateY(14px) rotate(-45deg);
          background-color: white;
        }
        &:nth-of-type(2) {
          opacity: 0;
        }
        &:nth-of-type(3) {
          -webkit-transform: translateY(-14px) rotate(45deg);
          transform: translateY(-14px) rotate(45deg);
          background-color: white;
        }
      }
    }
    .menuContent {
      background-color: rgba(0, 0, 0, 0.8);
      position: absolute;
      width: 230px;
      top: -30px;
      height: 120vh;
      transition: all 0.5s;
      .menus {
        list-style: none;
        margin-left: 20px;
        margin-top: 100px;
        li {
          font-size: 20px;
          color: white;
          line-height: 50px;
        }
      }
      .phoneMenuIcons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 50%;
        margin-left: 20px;
        margin-top: 10px;
        li {
          color: white;
          list-style: none;
          font-size: 30px;
        }
      }
    }
    .openMenu {
      right: 0;
    }
    .closeMenu {
      right: -230px;
    }
  }
}
