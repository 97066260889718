.leaf {
  width: 100%;
  height: 300px;
  background-size: cover;
  background-image: url(../images/leaf.jpg);
  background-repeat: no-repeat;
  overflow: hidden;
  position: relative;
  &:before {
    background: inherit;
    content: '';
    filter: blur(3px);
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    z-index: 0;
  }
  .titleContainer {
    position: relative;
    z-index: 20;
    padding: 110px 0;
    p {
      color: white;
    }
    div {
      background-color: white;
    }
  }
}
form {
  max-width: 600px;
  width: 90%;
  margin: 30px auto;
  .message {
    height: 150px;
  }
  div {
    width: 100%;
    // display: flex;
    // justify-content: flex-start;
    position: relative;
    height: 50px;
    label {
      text-align: left;
      display: block;
      min-width: 30%;
      justify-self: start;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    input {
      display: block;
      min-width: 70%;
      position: absolute;
      right: 0;
      top: 0;
      height: 50px;
      border: 2px solid #141414;
      box-sizing: border-box;
      padding-left: 15px;
    }
    select {
      display: block;
      width: 70%;
      position: absolute;
      top: 0;
      right: 0;
      height: 50px;
      border: 2px solid #141414;
      box-sizing: border-box;
      padding-left: 13px;
    }
    textarea {
      display: block;
      width: 70%;
      position: absolute;
      right: 0;
      top: 0;
      height: 150px;
      border: 2px solid #141414;
      box-sizing: border-box;
      padding: 10px 13px 10px 15px;
      font-size: 14px;
    }
  }
  .submitContainer {
    margin: 0 auto;
    display: block;
    width: 50%;
    p {
      text-align: center;
      font-size: 12px;
    }
  }
}
button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
  font-size: 16px;
}

@media (max-width: 768px) {
  form {
    div {
      flex-direction: column !important;
      margin: 5px 0;
      label {
        width: 100%;
        top: 0;
      }
      input {
        width: 100%;
        top: 11px;
      }
      select {
        top: 11px;
        width: 100%;
      }
      textarea {
        top: 11px;
        width: 100%;
      }
    }
  }
}
