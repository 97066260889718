@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alata&display=swap');
//font-family: 'Alata', sans-serif;

html,
body {
  margin: 0;
  padding: 0;
  font-family: 'Alata', sans-serif, 'Noto Sans JP', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #141414;
  font-size: 16px;
  background-color: white;
}
p,
ul,
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  padding: 0;
  color: #141414;
}
a {
  color: #141414;
  text-decoration: none;
}
.titleContainer {
  margin: 0px auto;
  padding: 20px 0;
  .engTitle {
    font-family: 'Alata', sans-serif;
    font-weight: bold;
    font-size: 25px;
    text-align: center;
  }
  .rhombus {
    background-color: #141414;
    height: 5px;
    width: 35px;
    transform: skewX(-30deg);
    margin: 8px auto;
  }
  .subTitle {
    font-size: 16px;
    text-align: center;
  }
}
.container {
  width: 85%;
  margin: 0 auto;
  max-width: 1000px;
}
input {
  padding: 0;
  margin: 0;
  border: none;
  border-radius: 0;
  background: white;
}
select {
  border: none;
  background: white;
  padding: 0;
}
textarea {
  padding: 0;
  border: 0;
  background: white;
}
.topViewImage {
  display: block;
  width: 100%;
  height: 200px;
  object-fit: cover;
}
.notFound {
  text-align: center;
  margin-top: 20px;
}
.tablist {
  .tab {
    color: #666;
    display: inline-block;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
  }
  .tab:hover {
    text-shadow: 1px 1px 8px rgba(0, 0, 0, 0.75);
  }
}
.mainFlex {
  display: flex;
  flex-direction: column;
  .mainColumn {
    flex-grow: 1;
    padding-top: 0px;
  }
}
@media (max-width: 800px) {
  .titleContainer {
    margin-top: 25px;
  }
}
