.detailPage {
  display: flex;
  flex-wrap: wrap;
  width: 95%;
  max-width: 900px;
  margin: 20px auto;
  justify-content: space-between;
  .sidebar {
    width: 30%;
    .postImage {
      width: 95%;
      display: block;
      margin: 0 auto;
      border-radius: 6px;
      object-fit: contain;
    }
    .information {
      width: 95%;
      margin: 10px auto;
      border: 2px solid #141414;
      .tile {
        background-color: #141414;
        color: white;
        text-align: center;
        height: 30px;
        line-height: 26px;
      }
      .infoTable {
        th {
          width: 33px;
          font-size: 15px;
          padding-right: 10px;
        }
        td {
          font-size: 15px;
        }
      }
    }
  }

  .maincol {
    width: 68%;
    .postTitle {
      font-size: 25px;
      font-weight: bold;
    }
    .detailCategory {
      margin-left: 10px;
    }
    .publishDate {
      display: inline-block;
      font-size: 14px;
      padding-left: 20px;
    }
    .body {
      ul {
        margin-left: 30px;
      }
      a {
        text-decoration: underline;
      }
    }
  }
}
.main2 {
  background-color: whitesmoke;
  padding: 0px 0 20px 0;
  margin-top: 40px;
}
.postTitle2 {
  display: none;
  font-size: 25px;
  font-weight: bold;
}
.title-day {
  display: none;
}

// ボタンホバー用
.seeMore {
  display: block;
  width: 150px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  margin: 10px auto;
  background-color: #1c1c1c;
  border: 2px solid #1c1c1c;
  color: #fff;
  position: relative;
  z-index: 2;
}
.seeMore:hover {
  background-color: #fff;
  border-color: #1c1c1c;
  color: #1c1c1c;
}
.seeMore::before,
.seeMore::after {
  position: absolute;
  z-index: -1;
  display: block;
  content: '';
  left: 0;
  width: 100%;
  height: 50%;
  background-color: #1c1c1c;
}
.seeMore,
.seeMore::before,
.seeMore::after {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.seeMore::before {
  top: 0;
}
.seeMore::after {
  bottom: 0;
}
.seeMore:hover::before,
.seeMore:hover::after {
  height: 0;
  background-color: #1c1c1c;
}

@media (max-width: 768px) {
  .detailPage {
    width: 90%;
    display: block;
    .sidebar {
      width: 100%;
      .postImage {
        width: 100%;
      }
      .information {
        width: 100%;
      }
    }
  }
  .maincol {
    width: 100% !important;
    .postTitle {
      font-size: 20px !important;
      display: none;
    }
  }
  .postTitle2 {
    display: block;
    margin-top: 70px;
  }
  .title-day2 {
    display: none !important;
  }
  .title-day {
    display: block;
  }
  .margin-left10 {
    margin-left: 10px;
  }
}
