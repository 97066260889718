.goldenRoad {
  width: 100%;
  height: 300px;
  background-size: cover;
  background-image: url(../images/lightup.jpg);
  background-repeat: no-repeat;
  overflow: hidden;
  position: relative;
  &:before {
    background: inherit;
    content: '';
    filter: blur(3px);
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    z-index: 0;
  }
  .titleContainer {
    position: relative;
    z-index: 20;
    padding: 110px 0;
    p {
      color: white;
    }
    div {
      background-color: white;
    }
  }
}
.message {
  display: flex;
  max-width: 1000px;
  margin: 0 auto;
  align-items: flex-start;
  img {
    z-index: 10;
    object-fit: contain;
    margin: 0 auto;
    margin-top: 30px;
    max-width: 40%;
  }
  div {
    padding: 20px;
    h2 {
      z-index: 0;
      background-color: #1c1c1c;
      margin: 20px 0 0 -70px;
      color: white;
      transform: skewX(-30deg);
      line-height: 50px;
      span {
        display: inline-block;
        transform: skewX(30deg);
        span {
          display: inline-block;
          transform: skewX(0deg);
          font-size: 14px;
          padding-right: 10px;
        }
      }
    }
    h3 {
      text-align: left;
      margin: 10px 0;
      font-size: 25px;
    }
    p {
      text-align: left;
    }
  }
}
.company {
  h4 {
    margin: 20px 0 30px 0;
  }
  text-align: center;
  .middleTitle {
    margin: 60px 0 30px 0;
  }
  .title {
    font-weight: 600;
    font-size: 16px;
    margin: 30px 0 10px 0;
  }
  .lighter {
    font-size: 14px;
  }
}
@media (max-width: 800px) {
  .message {
    flex-direction: column;
    img {
      max-width: 400px;
      width: 80%;
      display: block;
      margin: 0 auto;
      z-index: 0;
    }
    div {
      h2 {
        margin-top: -30px;
        z-index: 30;
      }
    }
  }
}
