.footer {
  background-color: #141414;
  // margin-top: 30px;
  .ichisanlaboF {
    font-size: 30px;
    font-family: 'Alata', sans-serif;
    font-weight: bold;
    text-align: center;
    line-height: 30px;
  }
  p {
    text-align: center;
    color: white;
    font-size: 12px;
  }
  .googleMap {
    display: block;
    background-color: yellow;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: white;
    margin: 10px auto;
    .googleMapIcon {
      display: block;
    }
  }
  small {
    display: block;
    text-align: center;
    color: white;
    font-size: 10px;
    margin-top: 20px;
    padding-bottom: 10px;
    a {
      color: white;
      border-bottom: 1px solid white;
    }
    .copyRight {
      color: white;
    }
  }
}
