.middleTitle {
  .box {
    background-color: #141414;
    display: inline-block;
    padding: 0 15px;
    margin: 0 auto;
    p {
      background-color: white;
      font-weight: 800;
      display: inline-block;
      padding: 0 10px;
      margin: 0 auto;
      transform: skewX(-20deg);
      span {
        display: inline-block;
        transform: skewX(20deg);
      }
    }
  }
}
